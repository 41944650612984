import {defineAsyncComponent} from 'vue'

export const AppComponents = {
    // Misc
    'script-contract-dialog': defineAsyncComponent(() => import('./Misc/ContractDialog.vue')),

    // Header
    'script-header-auth': defineAsyncComponent(() => import('./Layout/AuthItem.vue')),
    'script-header-basket': defineAsyncComponent(() => import('./Layout/CheckoutItem.vue')),
    'script-header-menu': defineAsyncComponent(() => import('./Layout/MenuItem.vue')),

    // Auth
    'script-auth-dialog': defineAsyncComponent(() => import('./Auth/DialogView.vue')),
    'script-auth': defineAsyncComponent(() => import('./Auth/AuthView.vue')),

    // Blocks
    'script-block-we-call-you': defineAsyncComponent(() => import('./Blocks/WeCallYouBlock.vue')),

    // Catalog
    'script-catalog-init': defineAsyncComponent(() => import('./Catalog/IndexCatalogView.vue')),
    'script-catalog-app-suggestion': defineAsyncComponent(() => import('./Catalog/AppSuggestionCatalogView.vue')),
    'script-catalog-price-suggestion': defineAsyncComponent(() => import('./Catalog/PriceSuggestionCatalogView.vue')),
    'script-catalog-feature': defineAsyncComponent(() => import('./Catalog/FeatureCatalogView.vue')),
    'script-catalog-variant': defineAsyncComponent(() => import('./Catalog/VariantCatalogView.vue')),
    'script-catalog-basket': defineAsyncComponent(() => import('./Catalog/BasketCatalogView.vue')),

    // Checkout
    'script-checkout': defineAsyncComponent(() => import('./Checkout/CheckoutView.vue')),
}
